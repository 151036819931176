import React, { useContext } from 'react';
import { LoadTagCoWithComponent } from 'allianz-library';

import AppContext from '../../App/App.context';
import sendFormValues from '../../api/form';
import Confirmation from '../Confirmation';
import ContactInformations from '../ContactInformations';
import Information from '../Information/Information';

function useSteps() {
  const { currentMediaConfig, currentStep, setCurrentStep, setIsLoading } = useContext(AppContext);

  async function onSubmit(values) {
    setIsLoading(true);

    try {
      await sendFormValues(values);

      setCurrentStep(currentStep + 1);
      setIsLoading(false);
      return null;
    } catch (error) {
      const errorMessage = (error && error.message) || 'Une erreur est survenue';

      return { error: errorMessage };
    }
  }

  const steps = [
    {
      name: 'Votre besoin',
      onValidate:
        currentMediaConfig === 'large'
          ? onSubmit
          : () => {
              setCurrentStep(currentStep + 1);
            },
      render: () => (
        <>
          <Information />
          {currentMediaConfig === 'large' && <ContactInformations />}
        </>
      ),
    },
    {
      name: 'Comment vous contacter ?',
      onValidate: onSubmit,
      render: () => (
        <LoadTagCoWithComponent pageName="form2" tagId="123">
          <ContactInformations />
        </LoadTagCoWithComponent>
      ),
    },
    {
      name: 'Confirmation',
      onValidate: () => {},
      render: () => <Confirmation />,
    },
  ];

  return currentMediaConfig !== 'large' ? steps : steps.filter((_, index) => index !== 1);
}

export default useSteps;
