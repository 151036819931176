import styled,  { css } from 'styled-components';

export const Title = styled.h4`
  font-weight: 700;
  ${({ theme }) =>
    theme.currentMediaConfig === 'large' &&
    css`
      font-size: 1.125rem;
    `}
  margin: 0 0 0.5rem;
`;

export const TitleForIe = styled.h4`
  font-weight: 700;
  ${({ theme }) =>
    theme.currentMediaConfig === 'large' &&
    css`
      font-size: 1.125rem;
    `}
  margin: -1.5rem 0 0.5rem;
`;

