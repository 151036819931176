import styled, { css } from 'styled-components';

import config from '../../../config';

export const ContactNoticeContainer = styled.div`
  margin: 1.5rem 0 2.5rem;
  text-align: left;

  ${config.grid.contactNotice}
`;

export const Title = styled.h4`
  font-weight: 700;
  ${({ theme }) =>
    theme.currentMediaConfig === 'large' &&
    css`
      font-size: 1.125rem;
    `}
  margin: 0 0 0.5rem;
`;

export const Notice = styled.p`
  font-style: italic;
  opacity: 0.875;
`;

export const Link = styled.a`
  color: #007ab3;
`;
