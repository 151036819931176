import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'allianz-library';

import AppContext from '../../App/App.context';
import {
  HeaderContainer,
  ProgressBarContainer,
  StepIndicatorsContainer,
  StepLabel,
  StepIndicator,
  MainTitle,
  VieImg,
} from './styledComponents';

function Header({ page, stepsName }) {
  const { currentMediaConfig } = useContext(AppContext);

  return (
    <HeaderContainer>
      {currentMediaConfig === 'large' ? (
        <ProgressBarContainer>
          <ProgressBar
            displayLabel
            maxStep={stepsName.length}
            renderLabel={() => (
              <StepIndicatorsContainer steps={stepsName}>
                {stepsName.map((name, index) => (
                  <StepLabel key={name} content={name} index={index} stepsLength={stepsName.length} />
                ))}
              </StepIndicatorsContainer>
            )}
            step={page + 1}
          />
        </ProgressBarContainer>
      ) : (
        <div>
          <StepIndicator>
            {page + 1}/{stepsName.length}
          </StepIndicator>
          <span>{stepsName[page]}</span>
        </div>
      )}
      <MainTitle>Assurance retraite</MainTitle>
      <VieImg />
    </HeaderContainer>
  );
}
Header.propTypes = {
  page: PropTypes.number.isRequired,
  stepsName: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Header;
